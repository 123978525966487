import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { combineClassNames } from '@utils/combineClassNames';
import Icon from '@common/components/icon';
import type { LooseObject } from '@common/types/util-types';

type RowItemType = LooseObject<{ id: string }>;

type ItemProps = {
  item: RowItemType;
  index: number;
  columns: { className?: string }[];
  rowProps?: Record<string, unknown> | ((item: RowItemType, index: number) => Record<string, unknown>);
  selectedRow?: string | undefined | null;
  createCells: (props: LooseObject<{ item: RowItemType }>) => React.ReactNode[];
  onChangeOrder?: (sourceId: string, itemId: string) => void;
  onDrop: () => void;
  refetchItems: () => Promise<unknown>;
  ActionComponent?: React.ComponentType<{ item: RowItemType, refetchItems: () => Promise<unknown> }>;
};

type DragItem = {
  id: string;
};

export const TableRow = React.memo(({
  item,
  index,
  columns,
  rowProps,
  selectedRow,
  createCells,
  onChangeOrder,
  onDrop,
  refetchItems,
  ActionComponent,
}: ItemProps) => {
  const ref = React.useRef(null);
  const props = typeof rowProps === 'function' ? rowProps(item, index) : rowProps;
  const cells = createCells({ ...props, item });

  const [, dropRef] = useDrop<DragItem, void, void>({
    accept: ['row'],
    hover: (source) => {
      if (source.id === item.id) return;
      onChangeOrder?.(source.id, item.id);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      className={combineClassNames('Table__Row', { 'Table__Row--selected': !!selectedRow && item.id === selectedRow })}
      // @ts-expect-error
      ref={onChangeOrder ? dropRef(previewRef(ref)) : undefined}
      style={{
        opacity: isDragging ? 0 : 1,
        ...(props?.onClick ? { cursor: 'pointer' } : {}),
      }}
      {...(props?.onClick ? { onClick: props.onClick } : {})}
    >
      {onChangeOrder && (
        <div className="Table__Cell Table__Action Table__Drag" ref={dragRef}>
          <Icon className="" type="drag_handle" />
        </div>
      )}
      {cells.map((cell, i) => {
        let className = 'Table__Cell';
        if (columns[i] && columns[i].className) className += ` ${columns[i].className}`;
        return <div className={className}>{cell}</div>;
      })}
      {ActionComponent && (
        <div className="Table__Cell Table__Action">
          <div className="Table__Action__Container">
            <ActionComponent item={item} refetchItems={refetchItems} {...props} />
          </div>
        </div>
      )}
    </div>
  );
});
